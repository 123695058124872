<template>
  <v-card
    :wxid="$options.name"
    :class="[{ unfocused: isTileUnfocused }, targetStatusClass, backgroundStatusClass]"
    class="tile product-sampling status-pictogram"
    tag="section"
    height="300"
    elevation="10"
  >
    <div class="tile-inner" v-if="status === 'completed'">
      <h3 :title="$t('common.sample')" class="primary-title">
        {{ `${$t("common.sample")} ${index}` }}
      </h3>
      <h2 class="primary-value">{{ `${formattedValue} ${unitName}` }}</h2>
      <section class="status-details">
        <br />
        <div class="pictogram" />
      </section>
    </div>
    <v-card-text v-if="status === 'pending'">
      <v-row justify="center" class="ma-0 pa-0">
        <h3 class="font-weight-regular text-center">{{ $t("dashboard.productSampling.samplingStatus.pending") }}</h3>
      </v-row>
    </v-card-text>
    <v-card-text v-if="status === 'waiting'">
      <v-row justify="center" class="mb-4">
        <h3 class="white--text font-weight-regular text-center">{{
          $t("dashboard.productSampling.samplingStatus.waiting")
        }}</h3>
      </v-row>
      <v-row justify="center">
        <v-progress-circular color="white" indeterminate size="70" width="7" />
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import TileBase from "@/components/dashboard/tiles/TileBase";
import Helpers from "@/helpers";
import { mapGetters } from "vuex";
import * as UnitUtils from "@/UnitUtils";

export default {
  name: "TileAssistedSample",
  extends: TileBase,
  inheritAttrs: false,
  props: {
    status: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    isOnTarget: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters("tiles", ["selectedTileIndex"]),
    unitName() {
      if (this.unit) {
        return UnitUtils.getUnitLabel(this.unit.toLowerCase());
      } else {
        return "";
      }
    },
    isTileUnfocused() {
      return this.selectedTileIndex !== null && this.tileIndex !== this.selectedTileIndex;
    },
    formattedValue() {
      return Helpers.round(this.value, 2);
    },
    targetStatusClass() {
      if (this.isOnTarget) {
        return this.successCssClass;
      } else {
        return this.errorCssClass;
      }
    },
    backgroundStatusClass() {
      switch (this.status) {
        case "completed":
          return null;
        case "pending":
          return this.samplePendingCssClass;
        case "waiting":
          return this.sampleWaitingCssClass;
        default:
          return this.defaultStateCssClass;
      }
    },
  },
};
</script>
