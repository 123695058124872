<template>
  <v-card class="samples pb-5 mt-10">
    <v-card-title class="mx-5">
      <v-row class="ma-0" align="center">
        <h2>{{ $t("dashboard.productSampling.samplesRequired") }}: {{ sampleCount }}</h2>
        <v-spacer />
        <h3 class="wx-typo-h3 mb-0 mr-2" style="line-height: 2rem;">
          {{ $t("dashboard.productSampling.averageGiveaway") }}:
        </h3>
        <p class="wx-typo-norm mb-0"> {{ formattedAverage }} {{ unitName }}</p>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row class="mx-5">
        <p class="wx-typo-norm mb-0">{{
          `${samplesTaken} / ${sampleCount} ${$t("dashboard.productSampling.samplesTaken")}`
        }}</p>
        <v-spacer />
        <p class="wx-typo-norm mb-0">{{ $t("common.minMax.minCap") }}: {{ minTolerance }} {{ unitName }}</p>
        <v-divider class="mx-5" vertical />
        <p class="wx-typo-norm mb-0">{{ $t("common.minMax.maxCap") }}: {{ maxTolerance }} {{ unitName }}</p>
      </v-row>
      <!-- Sample Tiles -->
      <div v-for="(row, index) in samples" :key="`sample-row-${index}`">
        <v-row v-if="samplingType === 'assisted'" class="mx-5 mt-10" justify="start">
          <tile-assisted-sample 
            v-for="sample in row"
            :key="`sample-index=${sample.index}`"
            class="col pa-0"
            :status="sample.status"
            :value="sample.value"
            :unit="sample.unit"
            :index="sample.index"
            :is-on-target="sample.isOnTarget"
          />
          <v-col
            v-for="i in MAX_SAMPLE_COUNT_PER_ROW - row.length"
            :key="`empty-col-sample-${index}-${i}`"
            class="pa-0 empty-tile"
          />
        </v-row>
        <v-row v-else class="mx-5 mt-10" justify="start">
          <tile-manual-sample
            v-for="sample in row"
            :key="`sample-index=${sample.index}`"
            class="col pa-0"
            :status="sample.status"
            :value="sample.value"
            :unit="sample.unit"
            :index="sample.index"
            :is-on-target="sample.isOnTarget"
            @sampleConfirmed="confirmSample"
            @sampleDeleted="deleteSample"
            @sampleUpdated="updateSample"
          />
          <v-col
            v-for="i in MAX_SAMPLE_COUNT_PER_ROW - row.length"
            :key="`empty-col-sample-${index}-${i}`"
            class="pa-0 empty-tile"
          />
        </v-row>
        <v-row class="mx-5 mt-2">
          <v-col v-for="sample in row" :key="`sample-step-index-${sample.index}`" class="pa-0 empty-tile mt-5">
            <v-divider class="progress-divider"/>
            <div v-if="sample.status !== 'completed'">
              <v-icon color="secondary" x-large>mdi-checkbox-blank-circle</v-icon>
            </div>
            <div v-else>
              <v-icon v-if="sample.isOnTarget" color="primary" x-large>mdi-check-circle</v-icon>
              <v-icon v-else color="error" x-large>mdi-close-circle</v-icon>
            </div>
            
            <v-divider class="progress-divider" />
          </v-col>
          <v-col
            v-for="i in MAX_SAMPLE_COUNT_PER_ROW - row.length"
            :key="`empty-col-step-${index}-${i}`"
            class="pa-0 empty-tile"
          />
        </v-row>
      </div>
      <v-row justify="end" class="mx-5 mt-10">
        <wx-textarea
          v-model="comment"
          :label="$t('common.comment')"
          :maxlength="maxCommentLength"
          :rules="[() => validateComment()]"
          no-resize
          clearable
        />
      </v-row>
      <v-row justify="end" class="mx-5 mt-10">
        <wx-btn-standard @click="cancelSession" class="mr-2" color="secondary">
          {{ $t("common.cancel") }}
        </wx-btn-standard>
        <wx-btn-standard @click="submitSession" :disabled="!allSamplesMeasured" color="primary">
          {{ $t("common.submit") }}
        </wx-btn-standard>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import TileAssistedSample from "@/components/productsamplingsession/TileAssistedSample";
import TileManualSample from "@/components/productsamplingsession/TileManualSample";
import WxBtnStandard from "../ui/WxBtnStandard.vue";
import GiveawaySamplingService from "@/components/productsamplingsession/GiveawaySamplingService";
import Helpers from "@/helpers";
import WxTextarea from "@/components/ui/WxTextarea.vue";
import { dash } from "@/helpers";
import * as UnitUtils from "@/UnitUtils";

const MAX_SAMPLE_COUNT_PER_ROW = 5;
const FETCH_SAMPLES_INTERVAL_TIME = 1000;

export default {
  name: "Samples",
  props: {
    sampleCount: {
      type: Number,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    samplingType: {
      type: String,
      required: true,
    },
  },
  components: {
    WxTextarea,
    TileAssistedSample,
    TileManualSample,
    WxBtnStandard,
  },
  data() {
    return {
      samples: [],
      rawSamples: [],
      samplesTaken: 0,
      fetchSamplesInterval: null,
      averageGiveaway: 0,
      unit: "kg",
      MAX_SAMPLE_COUNT_PER_ROW: MAX_SAMPLE_COUNT_PER_ROW,
      comment: null,
      maxCommentLength: 1000,
    };
  },
  computed: {
    ...mapGetters("dashboard", ["samplingSessionInProgress", "activeProductionUnitId"]),
    unitName() {
      if (this.unit) {
        return UnitUtils.getUnitLabel(this.unit.toLowerCase());
      } else {
        return "";
      }
    },
    minTolerance() {
      let tolerance = this.product?.target?.tolerance?.lower_value;
      let target = this.product?.target?.value ?? dash;
      return tolerance ?? target;
    },
    maxTolerance() {
      let tolerance = this.product?.target?.tolerance?.upper_value;
      let target = this.product?.target?.value ?? dash;
      return tolerance ?? target;
    },
    formattedAverage() {
      return Helpers.round(this.averageGiveaway, 2);
    },
    allSamplesMeasured() {
      return this.samplesTaken === this.sampleCount;
    },
  },
  watch: {
    samplesTaken() {
      if (this.allSamplesMeasured) this.$emit("samplesCompleted");
    },
  },
  methods: {
    ...mapActions("operation", ["handleHttpError", "showOperationError"]),
    clearFetchSamplesInterval() {
      clearInterval(this.fetchSamplesInterval);
    },
    validateComment() {
      if (this.comment && this.comment.trim().length > this.maxCommentLength) {
        return this.$t("dashboard.productSampling.errors.commentTooLong", { maxLength: this.maxCommentLength });
      }
      return true;
    },
    cancelSession() {
      this.$emit("cancel", { comment: this.comment });
    },
    submitSession() {
      this.$emit("submit", { comment: this.comment });
    },
    calculateSampleDisplay() {
      let samples = [];
      let sampleRow = [];
      let colIndex = 0;
      let samplesIndex = 0;
      for (let i = 0; i < this.sampleCount; i++) {
        if (samplesIndex < this.rawSamples.length) {
          let sample = this.rawSamples[samplesIndex];
          let isOnTarget = this.isSampleOnTarget(sample);
          sampleRow.push({
            status: "completed",
            index: i + 1,
            value: sample.measured_value,
            unit: sample.measured_unit,
            isOnTarget: isOnTarget,
          });
        } else {
          sampleRow.push({
            status: i === this.rawSamples.length ? "waiting" : "pending", // at initialization, only the first sample is in waiting
            index: i + 1,
            value: 0.0,
            unit: this.product?.target?.unit,
            isOnTarget: false,
          });
        }

        colIndex++;
        if (colIndex >= MAX_SAMPLE_COUNT_PER_ROW) {
          samples.push(sampleRow);
          colIndex = 0;
          sampleRow = [];
        }
        samplesIndex++;
      }
      if (sampleRow.length > 0) samples.push(sampleRow);
      this.samples = samples;
      this.samplesTaken = this.rawSamples.length;
      this.calculateAverageGiveaway();
    },
    isSampleOnTarget(sample) {
      let target = this.product?.target?.value ?? 0;
      let upper = this.product?.target?.tolerance?.upper_value;
      let lower = this.product?.target?.tolerance?.lower_value;
      let isOnTarget = false;
      if (upper && lower) {
        isOnTarget = sample.measured_value <= upper && sample.measured_value >= lower;
      } else { 
        isOnTarget = sample.measured_value === target;
      }
      return isOnTarget;
    },
    calculateAverageGiveaway() {
      if (this.rawSamples.length === 0) return 0;
      this.averageGiveaway =
        this.rawSamples.reduce((a, b) => {
          return a + b.measured_value;
        }, 0) / this.rawSamples.length;
    },
    fetchSamples() {
      GiveawaySamplingService.fetchSessionSamples(
        this.activeProductionUnitId,
        this.samplingSessionInProgress.session_id,
      )
        .then((httpResponse) => {
          this.rawSamples = httpResponse.data.sort((a, b) => a.sample_time - b.sample_time);
          this.calculateSampleDisplay();
        })
        .catch((httpError) => {
          // clear the interval so the user doesn't get an error message every 5 seconds
          clearInterval(this.fetchSamplesInterval);
          this.handleHttpError(httpError);
        });
    },
    startFetchSamplesInterval() {
      this.fetchSamples();
      this.fetchSamplesInterval = setInterval(this.fetchSamples, FETCH_SAMPLES_INTERVAL_TIME);
    },
    confirmSample(sample) {
      GiveawaySamplingService.createSessionSample(
        this.activeProductionUnitId, 
        this.samplingSessionInProgress.session_id, 
        sample.measured_value, 
        sample.measured_unit
      )
        .then(() => {
          this.fetchSamples();
        })
        .catch((httpError) => {
          if (this.containsError(httpError, "DSH_GS_POST_20002") || this.containsError(httpError, "DSH_GS_POST_20004")) {
            this.$emit("expired");
          } else {
            this.handleHttpError(httpError);
          }
        });
    },
    deleteSample(index) {
      let sampleId = this.rawSamples[index - 1]?.id;
      if (sampleId) {
        GiveawaySamplingService.deleteSessionSample(
          this.activeProductionUnitId, 
          this.samplingSessionInProgress.session_id, 
          sampleId
        )
          .then(() => {
            this.fetchSamples();
          })
          .catch((httpError) => {
            this.handleHttpError(httpError);
          });
      } else {
        this.showOperationError(this.$t("dashboard.productSampling.unsuccessfulDelete"));
      }
    },
    updateSample(sample) {
      let sampleId = this.rawSamples[sample.index - 1]?.id;
      if (sampleId) {
        GiveawaySamplingService.updateSessionSample(
          this.activeProductionUnitId, 
          this.samplingSessionInProgress.session_id, 
          sampleId,
          sample.measured_value, 
          sample.measured_unit
        )
          .then(() => {
            this.fetchSamples();
          })
          .catch((httpError) => {
            this.handleHttpError(httpError);
          });
      } else {
        this.showOperationError(this.$t("dashboard.productSampling.unsuccessfulUpdate"));
      }
    },
    containsError(httpError, code) {
      return httpError.response?.data?.errors?.map(err => err.code)?.includes(code);
    },
  },
  mounted() {
    this.calculateSampleDisplay();
    this.unit = this.product.target && this.product.target.unit ? this.product.target.unit : "units";
    this.startFetchSamplesInterval();
  },
  beforeDestroy() {
    // clear the interval before destroying the component
    this.clearFetchSamplesInterval();
  },
};
</script>

<style lang="scss" scoped>
.samples {
  background-color: var(--color-base-background);
}

.progress-divider {
  border-width: 2px;
  border-radius: 25px;
}
.completed {
  border-color: var(--color-success);
}
.not-completed {
  border-color: var(--color-error);
}
.empty-tile {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: calc(var(--grid-gutter) / 2);
  &:last-child {
    margin-right: 0;
  }
}
</style>
