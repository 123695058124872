<template>
  <v-card class="instructions pb-5 mt-10">
    <v-card-title class="ml-5 mb-5">
      <h2>{{ $t("dashboard.productSampling.instructions.label") }}</h2>
    </v-card-title>
    <v-card-text>
      <v-row class="mx-0" justify="center">
        <!-- Step #1 -->
        <v-card class="instruction-card col pa-10 mx-5">
          <v-card-text class="wx-typo-norm">
            <v-row align="start">
              <v-icon large class="col col-1 mr-1">mdi-numeric-1-circle</v-icon>
              <v-col>{{ $t("dashboard.productSampling.instructions.stepOne") }}</v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="instruction-icon">
            <v-icon x-large>mdi-scale</v-icon>
          </v-card-actions>
        </v-card>
        <!-- Step #2 -->
        <v-card class="instruction-card col pa-10 mx-5">
          <v-card-text class="wx-typo-norm">
            <v-row align="start">
              <v-icon large class="col col-1 mr-1">mdi-numeric-2-circle</v-icon>
              <v-col>{{ $t("dashboard.productSampling.instructions.stepTwo") }}</v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="instruction-icon">
            <v-icon x-large>mdi-card-bulleted-outline</v-icon>
          </v-card-actions>
        </v-card>
        <!-- Step #3 -->
        <v-card class="instruction-card col pa-10 mx-5">
          <v-card-text class="wx-typo-norm">
            <v-row align="start">
              <v-icon large class="col col-1 mr-1">mdi-numeric-3-circle</v-icon>
              <v-col>{{ $t("dashboard.productSampling.instructions.stepThree") }}</v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="instruction-icon">
            <v-icon x-large>mdi-button-pointer</v-icon>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "Instructions",
};
</script>

<style lang="scss" scoped>
.instruction-icon {
  display: flex;
  justify-content: center;
}
.instructions {
  background-color: var(--color-base-background);
}

.instruction-card {
  background-color: var(--color-secondary);
}
</style>
