<script>
import { VTextarea } from "vuetify/lib/components";

export default {
  name: "WxTextarea",
  extends: VTextarea,
  props: {
    filled: {
      type: Boolean,
      default: true,
    },
    dense: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.v-text-field {
  &__suffix {
    margin-top: 15px;
  }
  // Form headings
  &.h2 {
    .v-input__slot {
      .v-text-field__slot {
        font-size: var(--font-size-h2);
      }
    }
  }
  &.h3 {
    .v-input__slot {
      .v-text-field__slot {
        font-size: var(--font-size-h3);
      }
    }
  }
  &.equal-before,
  &.equal-after {
    position: relative;
  }
  &.equal-before::before,
  &.equal-after::after {
    position: absolute;
    top: 0.5rem;
    content: "\003d"; // equal character
    font-size: var(--font-size-h2);
  }
  &.equal-before::before {
    left: -1.05rem;
    @media ($wx-lg-min) {
      left: -1.1rem;
    }
    @media ($wx-xl-min) {
      left: -1.2rem;
    }
  }
  &.equal-after::after {
    right: -1.05rem;
    @media ($wx-lg-min) {
      right: -1.1rem;
    }
    @media ($wx-xl-min) {
      right: -1.2rem;
    }
  }
  /**
  * Filter styled field
  * Wx Design request no border-bottom
  */
  &.filter-style {
    .v-input__control {
      .v-input__slot {
        border-radius: var(--border-radius-form-elements);

        // hide border-bottom
        &::before,
        &::after {
          display: none;
          border-color: transparent !important;
        }
      }
    }
  }
  /**
   * Prop `fieldNotDense`
   * This class makes the dropdown the same height as other fields
   * Used in `ui/WxSelectorDialog.vue` for JustificationPanel
   */
  &.field-not-dense {
    &.v-text-field,
    &.v-text-field--single-line {
      > .v-input__control {
        > .v-input {
          &__slot {
            min-height: 41px;
          }
        }
      }
      // label on top
      &--filled {
        .v-label {
          top: 12px;
        }
      }
      // vertically align right chevron icon
      &--enclosed {
        .v-input {
          &__append-inner {
            margin-top: 10px;
          }
        }
      }
    }
    &.v-text-field,
    &.v-text-field--single-line {
      // vertically align v-icon
      .v-input__prepend-inner {
        margin-top: 10px;
      }
    }
  }
}
</style>
